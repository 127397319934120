import {DataTableHeader} from "vuetify";
import VueI18n from "vue-i18n";
import TranslateResult = VueI18n.TranslateResult;

export default class ColumnDef implements DataTableHeader {
    public text: string;
    public value: string;
    public sortable: boolean;
    public isDate: boolean;
    public align?: 'start' | 'center' | 'end'


    constructor(args: {text: TranslateResult; value: string; align?: 'start' | 'center' | 'end'; sortable?: boolean; isDate?: boolean}) {
        this.text = (typeof args.text === "string") ? args.text : args.text.toString()
        this.value = args.value;
        this.align = this.align || 'center'
        this.sortable = args.sortable || false;
        this.isDate = args.isDate || false;
    }
}
