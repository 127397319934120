
import {Component, Prop, Vue} from 'vue-property-decorator';
import {formatISODateTime, isISODateTime} from "../filters/filters";
import {InjunctionTakeoverStatus} from "../common/enum/injunction-takeover-status";

@Component({
    filters: {
        formatISODateTime
    }
})
export default class DocumentDetails extends Vue {
  @Prop() public rootResourceKey!: string;
  @Prop() public documentData!: any;
  @Prop({default: null}) public injunctionTakeoverType!: string | null;
  @Prop({default: null}) public injunctionTakeoverStatus!: string | null;
  @Prop({default: null}) public customDisplayValue!: (key: string, value: any) => any | null;
  @Prop({default: null}) public keysToIgnore!: string[] | null;

  private isISODateTime = isISODateTime

  public displayValue(key: string, value: any) {
    if (key === 'type') {
      return this.$t(`document.types.${value}`);
    }
    if (key === 'status' && this.injunctionTakeoverStatus) {
      return this.customDisplayValue(key, this.injunctionTakeoverStatus);
    }

    if (this.customDisplayValue) {
      return this.customDisplayValue(key, value);
    }

    return value;
  }

  private filteredObjectKeys(documentData: any) {
    return Object.keys(documentData).filter(s => !s.toLowerCase().includes('id'));
  }

  private isKeyIgnored(key: string) {
    return this.keysToIgnore == null || !this.keysToIgnore.includes(key)
  }

  get isAtveheto() {
    return this.injunctionTakeoverStatus === InjunctionTakeoverStatus.ATVEHETO
  }

  get isLejart() {
    return this.injunctionTakeoverStatus === InjunctionTakeoverStatus.LEJART
  }

  get isAtvettNemElerheto() {
    return this.injunctionTakeoverStatus === InjunctionTakeoverStatus.ATVETT_NEM_ELERHETO
  }

  get info7() {
    return `injunction-takeover.text.info-7${this.injunctionTakeoverType === "Cg" ? 'cg' : ''}`;
  }
}
