
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import DataTablePagination from "../common/dto/data-table-pagination";
import ColumnDef from "../common/column-def";
import {AxiosPromise} from "axios";
import {formatISODateTime} from "../filters/filters";
import {PagedRequest} from "../client";

@Component({
    components: {},
    filters: {
        formatISODateTime,
    }
})
export default class TableComponent extends Vue {
    @Prop() public headers?: ColumnDef[] | null;
    @Prop() public retrievePaginatedData?: ((pagination: PagedRequest) => AxiosPromise) | null;
    @Prop() public onDeleteRow?: ((item: any) => AxiosPromise) | null;
    @Prop() public displayStatus?: (value: any) => any;
    @Prop({default: true}) public rowClickable!: boolean;
    @Prop({default: false}) public enableMultiSelect!: boolean;
    @Prop({default: null}) public acknowledgementSignable?: ((item: any) => boolean) | null;
    @Prop({default: null}) public disableDownload?: ((item: any) => boolean) | null;

    private readonly rowsPerPageKey = 'TableComponentRowsPerPage';

    public tableData: any[] = [];
    public selected: any[] = [];
    private pageSizes = [5, 10, 50];
    private rowsPerPage = this.pageSizes.at(0)!!;

    public pagination: DataTablePagination = {
        pageIndex: 1,
        displayedPerPage: this.rowsPerPage,
    };

    getPageInfo() {
        const maxPageEnd = this.pagination.pageIndex * this.rowsPerPage;
        return {
            pageStart: this.pagination.totalItems === 0 ? 0 : ((this.pagination.pageIndex! - 1) * this.rowsPerPage + 1),
            pageEnd: maxPageEnd > this.pagination.totalItems! ? this.pagination.totalItems! : maxPageEnd,
            totalItems: this.pagination.totalItems,
        };
    }

    public mounted() {
        this.setRowsPerPageFromStorage();
        this.retrieveData();
        this.$emit('delegate', this);
    }

    public downloadDocument(document: any) {
        this.$emit('download', document)
    }

    public displayDocumentDetails(document: any) {
        if (this.rowClickable) {
            this.$emit('openDialog', document);
        }
    }

    public isDownloadDisabled(item: any) {
        return this.disableDownload == null ? false : this.disableDownload!!(item)
    }

    public extendedTableData() {
        if (this.enableMultiSelect && this.tableData) {
            return this.tableData.map(data => ({...data, isSelectable: this.acknowledgementSignable!!(data)}));
        } else {
            return this.tableData
        }
    }

    @Watch('pagination.pageIndex')
    public paginationChanged() {
        this.retrieveData();
    }

    @Watch('retrievePaginatedData')
    public dataChanged() {
        this.retrieveData();
    }

    @Watch('rowsPerPage')
    public rowsPerPageChanged() {
        this.pagination.pageIndex = 1
        this.retrieveData();
        localStorage.setItem(this.rowsPerPageKey, this.rowsPerPage.toString());
    }

    public retrieveData() {
        if (this.retrievePaginatedData) {
            this.selected = []
            const paging: PagedRequest = {
                paging: {
                    index: this.pagination.pageIndex - 1,
                    displayedPerPage: this.rowsPerPage,
                }
            };
            this.retrievePaginatedData(paging).then((response) => {
                    this.tableData = response.data.content;
                    this.pagination.pageIndex = response.data.pageIndex + 1;
                    this.pagination.displayedPerPage = response.data.displayedPerPage;
                    this.pagination.totalItems = response.data.totalElements;
                    this.pagination.totalPages = response.data.totalPages;
                }
            )
        }
    }

    private deleteRow(item: any) {
        if (this.onDeleteRow) {
            this.onDeleteRow(item).then(
                () => this.retrieveData(),
            );
        }
    }

    private setRowsPerPageFromStorage() {
        const localStorageRowsPerPage = parseInt(localStorage.getItem(this.rowsPerPageKey) ?? "");
        if (this.pageSizes.includes(localStorageRowsPerPage)) {
            this.rowsPerPage = localStorageRowsPerPage;
        }
    }
}
