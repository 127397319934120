
import {Component, Vue} from 'vue-property-decorator';
import TableComponent from "../components/TableComponent.vue";
import ColumnDef from "../common/column-def";
import CustomPageTitle from "../components/CustomPageTitle.vue";
import DocumentDetails from "../components/DocumentDetails.vue";
import {AnnouncementControllerApi, AnnouncementDto, NotificationDetailsDto, NotificationDto, PagedRequest, SubmissionControllerApi} from "../client";
import http from "../plugins/http";
import {FileDownloadService} from "../service/file-download-service";

@Component({
    components: {
        CustomPageTitle,
        TableComponent,
        DocumentDetails,
        AnnouncementTableComponent: () => import('../components/AnnouncementTableComponent.vue'),
        DocumentDetailsModal: () => import('../components/DocumentDetailsModal.vue')
    },
})
export default class Documents extends Vue {
    public fileDownloadService = new FileDownloadService();
    public submissionControllerApi = new SubmissionControllerApi(undefined, process.env.VUE_APP_API_BASE_URL, http)
    public documentData: NotificationDetailsDto | null = null;

    public dialog = false;

    public submissionHeaders = [
        new ColumnDef({text: this.$t('submissions.subject'), value: 'subject'}),
        new ColumnDef({text: this.$t('submissions.recipient'), value: 'recipient'}),
        new ColumnDef({text: this.$t('submissions.status'), value: 'status'}),
        new ColumnDef({text: this.$t('submissions.created'), value: 'created', isDate: true}),
        new ColumnDef({text: this.$t('submissions.download'), value: 'download'}),
    ];

    public activeTab = 0;
    public tabs = [
        {id: 0, name: this.$t('document-details.details')},
        {id: 1, name: this.$t('document-details.related-documents')},
    ];
    private announcementControllerApi = new AnnouncementControllerApi(undefined, process.env.VUE_APP_API_BASE_URL, http);

    public displayValues(key: string, value: string): any {
        if (key === 'status') {
            return this.displayStatus(value);
        }

        return value;
    }

    public displayStatus = (value: string) => {
        if (value === 'kézbesített') {
            return this.$t(`submissions.status-type.processed`);
        } else if (value === 'ismeretlen') {
            return this.$t(`submissions.status-type.unknown`);
        } else {
            return this.$t(`submissions.status-type.other`);
        }
    }

    private retrieveSubmissions(request: PagedRequest) {
        return this.submissionControllerApi.retrieveSubmissions(request);
    }

    private retrieveDetailedInformation(documentData: NotificationDto) {
        this.submissionControllerApi.getSubmission(documentData.id!!).then((response) => {
                this.documentData = response.data;
            }
        )
    }

    private retrieveRelatedAnnouncements(request: PagedRequest) {
        if (this.documentData && this.documentData.id) {
            return this.submissionControllerApi.getAnnouncements(this.documentData.id, request);
        }
    }

    private openDialog(value: NotificationDto) {
        this.activeTab = 0;
        this.retrieveDetailedInformation(value);
        this.dialog = true;
    }

    private closeDialog(value: boolean) {
        this.dialog = value;
    }

    private downloadFile(value: NotificationDetailsDto) {
        const id = value ? value.id : this.documentData?.id!
        this.submissionControllerApi.downloadFile(id!!).then(value => this.fileDownloadService.downloadFile(value.data));
    }

    private downloadAnnouncement(announcement: AnnouncementDto) {
        this.announcementControllerApi.downloadAnnouncement(announcement.id!!).then(response => this.fileDownloadService.downloadFile(response.data))
    }
}
